import { CSSProperties, useState, useMemo, useEffect } from "react";
import classes from "./CWForm.module.css";
import { CustomFormProps } from ".";
import LinkForm, { LinkData } from "../widgetFrom/LinkForm";
import { SettingItem, SettingItems } from "../SettingFormItems";
import FormInputItem from "../FormInputItem";

export const objectKeys = Object.keys as <T>(o: T) => (keyof T)[];

const linkData = {
  hyperLinkText: "",
  url: "",
  urlType: "category",
};

type SettingsNames =
  | "isSlider"
  | "width"
  | "height"
  | "objectFit"
  | "col"
  | "autoplay"
  | "delay"
  | "gap";

const bannerSettings: SettingItem<SettingsNames>[] = [
  {
    name: "Is Slider",
    key: "isSlider",
    type: "checkbox",
  },
];

const NewsScrollerForm = ({
  formItems,
  customForm = false,
  name = "",
  onSubmit = () => {},
  title,
  className,
  style,
}: CustomFormProps) => {
  const [news, setNews] = useState<
    { id: number; value: string; link?: LinkData }[]
  >((formItems as any)?.news || []);

  const [isSlider, setIsSlider] = useState(
    (formItems as any)?.isSlider || false
  );

  const [slideDuration, setSlideDuration] = useState(
    (formItems as any)?.slideDuration || false
  );

  useEffect(() => {
    if (news.length === 0)
      setNews([{ id: Date.now(), value: "", link: linkData }]);
  }, [news]);

  const classNames = [classes.CWForm, className];

  const handleSubmit = () => {
    onSubmit({
      ...formItems,
      news: news,
      isSlider,
      slideDuration,
    } as any);
  };

  return (
    <div className={classNames.join(" ")} style={style}>
      <form className="mainSliderForm__container">
        <h1 className="title capitalize">{title}</h1>

        <div className="mainSliderForm__inputContainer">
          {/* <label className="mainSliderForm__label">Offer Banner</label> */}
          <div className="mainSliderForm__input">
            <div className="flex flex-col gap-2">
              <FormInputItem
                label={"Is Slider"}
                value={isSlider}
                type="checkbox"
                onChange={(value) => {
                  setIsSlider(value);
                }}
              />
              <FormInputItem
                label="Slide Duration in Second"
                value={slideDuration}
                placeholder="5"
                type="number"
                onChange={(value) => {
                  setSlideDuration(value);
                }}
              />
            </div>
            {news?.map((item, index) => (
              <div
                key={item.id}
                className="border-t border-gray-400 pt-3 first:border-none"
              >
                <div className="flex gap-3 mb-3">
                  <input
                    type="text"
                    placeholder="Enter Text"
                    value={item.value || ""}
                    onChange={(e) => {
                      setNews((prev) => {
                        const temp = [...prev];
                        temp[index] = { ...temp[index], value: e.target.value };
                        return temp;
                      });
                    }}
                  />

                  {/* remove button with inline tailwind */}
                  <button
                    disabled={news.length <= 1}
                    onClick={(e) => {
                      e.preventDefault();
                      setNews((prev) => prev.filter((_, i) => i !== index));
                    }}
                    className="w-14 text-2xl disabled:bg-gray-300 disabled:border-none disabled:text-white disabled:cursor-not-allowed text-red-500 hover:text-white border border-red-300 hover:bg-red-300 rounded-md"
                  >
                    {/* Trash icon */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 mx-auto"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <LinkForm
                  currnetLinkData={
                    (formItems as any)?.news?.[index]?.link || linkData
                  }
                  onUpdate={(data) => {
                    setNews((prev) => {
                      const temp = [...prev];
                      temp[index] = { ...temp[index], link: data };
                      return temp;
                    });
                  }}
                />
              </div>
            ))}
          </div>

          {/* add new button */}
          <div className="mainSliderForm__addNewBtnContainer">
            <button
              onClick={(e) => {
                e.preventDefault();
                setNews((prev) => [...prev, { id: Date.now(), value: "" }]);
              }}
              className="w-full p-2 py-1 text-2xl text-blue-500 hover:text-white border border-blue-300 hover:bg-blue-300 rounded-full"
            >
              +
            </button>
          </div>
        </div>

        <div>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="mainSliderForm__btn"
          >
            save
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewsScrollerForm;
