import React, { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { checkToken } from "../../App";
import { SubMenu } from "../../features/addMenu";
import { NestingParams } from "../../pages/MenuDetails";

interface Props {
	choosenItem: SubMenu;
	chosenIndex: string;
	subMenus: SubMenu[];
	onUpdate: (
		array: SubMenu[],
		deleteIndices: number[],
		insertIndicies: number[],
		toBeInserted: SubMenu,
		optionalParams: NestingParams
	) => void;
}

const MenuForm: FC<Props> = ({
	choosenItem,
	chosenIndex,
	subMenus,
	onUpdate,
}) => {
	const [name, setName] = useState("");
	const [url, setUrl] = useState("");
	const [menuType, setMenuType] = useState<"default" | "full">();
	const [numberIndex, setNumberIndex] = useState<number[]>([]);

	useEffect(() => {
		setName(choosenItem.name);
		setUrl(choosenItem.url);
		if (choosenItem.menuType === "full") {
			setMenuType("full");
		}
		setNumberIndex(convertToIntegerIndex(chosenIndex));
	}, [choosenItem]);

	const convertToIntegerIndex = (indexes: string) => {
		return indexes.length > 1
			? indexes.split("_").map((str) => {
					return Number(str);
			  })
			: [Number(indexes)];
	};
	const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	};

	const handleUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUrl(e.target.value);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		checkToken();
		e.preventDefault();
		const updatedData: SubMenu = {
			name,
			url,
			submenu: choosenItem.submenu,
			type: choosenItem.type,
			...(choosenItem.menuType && { type: choosenItem.menuType }),
			...(menuType && { menuType: menuType }),
		};
		onUpdate(
			JSON.parse(JSON.stringify(subMenus)),
			numberIndex,
			numberIndex,
			updatedData,
			{ type: "deep", updateOnly: true }
		);
		const Toast = Swal.mixin({
			toast: true,
			position: "bottom-end",
			showConfirmButton: false,
			timer: 2500,
		});
	};
	return (
		<>
			{choosenItem.name && (
				<form
					onSubmit={handleSubmit}
					className="mainSliderForm__container">
					<div>
						<div>
							<div className="capitalize tracking-wide">
								Name :
							</div>
							<input
								value={name}
								onChange={handleName}
								type="text"
								placeholder="Type here...."
							/>
						</div>
						<div className="mt-3">
							<div className="capitalize tracking-wide">
								{choosenItem.type !== "custom" ? "Slug" : "Url"}{" "}
								:
							</div>
							<input
								value={url}
								onChange={handleUrl}
								type="text"
								placeholder="Type here...."
								disabled={choosenItem.type !== "custom"}
								className={
									(choosenItem.type?.length as number) > 0
										? "text-gray-500"
										: ""
								}
							/>
						</div>
						{/* full or default menu select type */}
						<div className="mt-3">
							<div className="capitalize tracking-wide">
								Menu Type :
							</div>

							<select
								className="w-full"
								onChange={(e) =>
									setMenuType(
										e.target.value as "default" | "full"
									)
								}
								value={menuType}>
								<option value="default">Default</option>
								<option value="full">Full</option>
							</select>
						</div>
					</div>
					<div>
						<button
							className={
								name === choosenItem.name &&
								url === choosenItem.url &&
								menuType === choosenItem.menuType
									? "dissabledSliderForm__btn"
									: "mainSliderForm__btn"
							}
							disabled={
								name === choosenItem.name &&
								url === choosenItem.url &&
								menuType === choosenItem.menuType
							}>
							Update
						</button>
					</div>
				</form>
			)}
		</>
	);
};

export default MenuForm;
