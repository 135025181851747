import { useState } from "react";
import { _axios } from "../api";
import Cookies from "js-cookie";
import { envs } from "../utils/constants";
import { useDetectClickOutside } from "react-detect-click-outside";
import { ProductItemGet } from "../types/product";
import CloseIcon from "./icons/CloseIcon";

const ProductSearch = ({
  choosenProducts,
  setChoosenProduct,
}: {
  choosenProducts: ProductItemGet[];
  setChoosenProduct: (product: ProductItemGet | undefined) => void;
}) => {
  const [text, setText] = useState<string>("");
  const [products, setProdcuts] = useState<any[]>([]);
  const [show, setShow] = useState<boolean>(true);

  const handleRemoveProductId = (index: number) => {
    // const filteredProducts = choosenProducts.filter((_, i) => i !== index);
    // setChoosenProducts(filteredProducts);
    // dispatch(getCustomProducts(filteredProducts as any[]));
    // onSetProducts(filteredProducts);
    setChoosenProduct(undefined);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    const _text: string = e.target.value;
    getProducts(_text.trim());
  };

  const getProducts = async (_text: string) => {
    await _axios
      .get(envs.frontendApiUrl + "product/storefront?search=" + _text)
      .then((res) => {
        setProdcuts(res.data?.data);
        setShow(true);
      });
  };

  const handleChoosenProduct = (product: ProductItemGet) => {
    // const _choosenProducts = concatProductsUnique([product], choosenProducts);
    // setChoosenProducts(_choosenProducts)

    // dispatch(getCustomProducts(_choosenProducts as any[]))
    // onSetProducts(_choosenProducts);
    setChoosenProduct(product);
    setText("");
    setShow(false);
  };

  const hideProductList = () => {
    setShow(false);
  };

  const ref = useDetectClickOutside({ onTriggered: hideProductList });

  console.log({ choosenProducts });

  return (
    <div className="py-4 relative">
      <input
        value={text}
        type="text"
        placeholder="Add custom products..."
        onChange={handleOnChange}
      />
      <div className="absolute w-full top-full left-0 z-50">
        {products?.length > 0 && show && (
          <div
            className="bg-white px-3 shadow-lg divide-y max-h-96 min-h-fit overflow-y-auto"
            ref={ref}
          >
            {products?.map((product) => {
              return (
                <div key={product.id}>
                  <div
                    className="flex items-center gap-4 cursor-pointer py-2"
                    onClick={() => handleChoosenProduct(product)}
                  >
                    {/* <img src={process.env.REACT_APP_SELLER_IMAGE_URL + product?.thumbnail} alt="" className='w-12 h-12 object-contain'/> */}
                    <div className="text">
                      <div className="text-xs font-light w-full">
                        <span className="font-bold">Product:</span>{" "}
                        {product?.name}
                      </div>
                      <div className="text-xs font-light w-full">
                        <span className="font-bold">Sku:</span> {product?.sku}
                      </div>
                      <div className="text-xs font-light flex items-center w-full justify-start">
                        {/* <span className='font-bold'>Price:</span>
                                {
                                    product.discount_price ? <div>
                                    <span className='text-gray-400 line-through mr-3'>	
                                    &#2547;{product?.purchase_price}</span><span className='text-red-500'>	
                                    &#2547;{product?.discount_price}</span></div> : <div className='text-red-500'>{product.purchase_price}</div>
                                } */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="relative">
        <div className="">
          {choosenProducts.map((item: ProductItemGet, index: number) => {
            return (
              <div
                key={index}
                className="relative flex h-full items-center bg-white shadow-lg rounded-md mb-2 last:mb-4"
              >
                {/* <div className="">
                                    <img src={process.env.REACT_APP_SELLER_IMAGE_URL + item?.thumbnail} alt={item?.name} />
                                </div> */}

                <div className="capitalize text-xs font-light tracking-wide p-3">
                  <div className="mb-2">
                    <span className="font-bold">Product: </span>
                    {item?.name}
                  </div>
                  <div>
                    <span className="font-bold">Sku: </span>
                    {item?.sku || item?.monarchmart_sku}
                  </div>
                </div>
                <div
                  onClick={() => handleRemoveProductId(index)}
                  className="absolute -top-3 -right-3 z-30 bg-white text-red-500 rounded-full p-1 cursor-pointer shadow-lg"
                >
                  <CloseIcon />
                </div>
              </div>
            );
          })}

          {choosenProducts.length === 0 && (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-400 text-sm">No products added yet</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductSearch;
