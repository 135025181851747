import React, { FC, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { getCampaignList } from "../../../utils/requests";
import { Widget } from "../../../widgetList/widgetList";
import CloseIcon from "../../icons/CloseIcon";
import LinkForm, { LinkData } from "../LinkForm";

interface Props {
	choosenItem: Widget;
	onUpdate: (w: Widget) => void;
}

const FomrForTile: FC<Props> = ({ choosenItem, onUpdate }) => {
	const [active, setActive] = useState(false);
	const [showTimer, setShowTimer] = useState(
		choosenItem.data?.showTimer ?? true
	);
	const [title, setTitle] = useState("");
	const [link, setLink] = useState<LinkData>({} as LinkData);
	const [campaign, setCampaign] = useState<any>(
		(choosenItem.data as any)?.campaign ?? undefined
	);
	const [searchText, setSearchText] = useState("");
	const [campaigns, setCampaigns] = useState<any[]>([]);
	const [showCampaign, setShowCampaign] = useState(false);

	const closePageCampaignResult = () => {
		setShowCampaign(false);
	};

	const refPageCampaignResult = useDetectClickOutside({
		onTriggered: closePageCampaignResult,
	});

	useEffect(() => {
		setTitle(choosenItem.title || "");
		setLink(choosenItem.link || ({} as LinkData));
	}, [choosenItem]);

	const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const titleObj = {
			name: "title",
			active: active,
			title: title,
			link: link,
			wType: choosenItem.wType,
			id: choosenItem.id,
			data: {
				showTimer: showTimer,
				campaign: campaign,
			},
		};
		onUpdate(titleObj);
	};

	const handleLinkData = (data: LinkData) => {
		setLink(data);
	};

	const handlePageCampaignSearch = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setSearchText(e.target.value);

		getPageCampaigns(e.target.value);

		if (campaigns?.length > 0) {
			setShowCampaign((prev) => (prev = !prev));
		}
	};

	const getPageCampaigns = async (text?: string) => {
		await getCampaignList({
			search: text,
			perPage: 20,
		}).then((res) => {
			setCampaigns(res?.data);
		});
	};

	const handleChoosePageCategory = (index: number) => {
		setCampaign(campaigns[index]);
		setShowCampaign(false);
	};

	console.log(
		"campaigns",
		campaigns,
		"campaign",
		campaign,
		"showCampaign",
		showCampaign,
		"searchText",
		searchText
	);

	return (
		<>
			{choosenItem.name === "title" ? (
				<form
					onSubmit={handleSubmit}
					className="mainSliderForm__container">
					<h1 className="title capitalize">title</h1>
					{/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
					<div>
						<div>
							<div className="capitalize tracking-wide">
								title :
							</div>
							<input
								value={title}
								onChange={handleTitle}
								type="text"
								placeholder="type here...."
							/>
						</div>
						<div className="mt-3">
							<LinkForm
								currnetLinkData={link}
								onUpdate={handleLinkData}
							/>
						</div>

						<div className="checkbox">
							<div>Show Campaign Timer: </div>
							<input
								type="checkbox"
								defaultChecked={showTimer}
								onChange={() => setShowTimer((p) => !p)}
							/>
						</div>

						{showTimer && (
							<>
								<div className="text-sm tracking-wide mb-1">
									Timer Campaign :
								</div>
								{/* {campaigns?.map((item: any, index) => {
							return (
							);
						})} */}
								{campaign && (
									<div className="relative flex items-center justify-between p-3 bg-white drop-shadow-lg mb-3 rounded-md text-sm font-light">
										<div className="truncate pr-6 w-[80%]">
											{campaign.name}({campaign.slug})
										</div>
										<div className="w-[20%]">
											ID: {campaign.id}
										</div>
										<div
											className="absolute z-10 -top-2 -right-2 bg-white text-red-500 rounded-full drop-shadow-lg p-1 cursor-pointer"
											onClick={() =>
												setCampaign(undefined)
											}>
											<CloseIcon />
										</div>
									</div>
								)}
								{!campaign && (
									<div className="relative">
										<input
											type="text"
											placeholder="Search Campaigns..."
											onChange={handlePageCampaignSearch}
										/>
										{showCampaign && (
											<div
												ref={refPageCampaignResult}
												className="absolute top-10 left-0 right-0 max-h-40 min-h-fit overflow-y-auto bg-white my-2 px-2 divide-y z-10">
												{campaigns?.map(
													(item, index) => {
														return (
															<div
																onClick={() =>
																	handleChoosePageCategory(
																		index
																	)
																}
																key={index}
																className="p-2 text-sm tracking-wide truncate cursor-pointer">
																{item.name}
															</div>
														);
													}
												)}
											</div>
										)}
										{/* <div
								className="w-full bg-green-500 text-white text-center text-sm tracking-wider py-2 rounded-md mt-2 cursor-pointer"
								onClick={handleSavePageCategory}>
								Add Category
							</div> */}
									</div>
								)}
							</>
						)}
					</div>
					<div>
						<button className="mainSliderForm__btn">save</button>
					</div>
				</form>
			) : (
				<div className="flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52">
					Please check widget name!
				</div>
			)}
		</>
	);
};

export default FomrForTile;
