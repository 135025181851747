import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { handlePageWidget } from "../../../features/addPages/addPages";
import { setGlobalPopupElement } from "../../../features/popupSlice";
import { Widget } from "../../../widgetList/widgetList";
import { ImageContainerEditButton } from "../../components";
import CloseIcon from "../../icons/CloseIcon";
import ImagePopUpV2 from "../../ImagePopUpV2";
import ImageSelector, { ImageSelectorV2 } from "../../ImageSelector";
import LinkForm, { LinkData } from "../LinkForm";
import PopupWrapper from "../../PopupWrapper";
import SortList from "../../ListSort";
import ImageSortWithTitle from "../../ImageSortWithTitle";
import SettingFormItems, {
  SettingItem,
  SettingItems,
} from "../../SettingFormItems";
import ImageItem from "../../ImageItem";

interface Props {
  choosenItem: Widget;
  onUpdate: (w: Widget) => void;
}
interface Image {
  image: string;
  title: string;
  link: LinkData;
}

type Panel = "main" | "settings";
const panels: {
  name: Panel;
  title: string;
}[] = [
  {
    name: "main",
    title: "Main",
  },
  {
    name: "settings",
    title: "Settings",
  },
];

type SettingsNames =
  | "isSlider"
  | "width"
  | "height"
  | "objectFit"
  | "col"
  | "autoplay"
  | "delay"
  | "gap";

const bannerSettings: SettingItem<SettingsNames>[] = [
  {
    name: "Is Slider",
    key: "isSlider",
    type: "checkbox",
  },
  {
    name: "Width",
    key: "width",
    type: "number",
  },
  {
    name: "Height",
    key: "height",
    type: "number",
  },
  {
    name: "Object Fit",
    key: "objectFit",
    type: "select",
    options: [
      { name: "Contain", value: "contain" },
      { name: "Cover", value: "cover" },
    ],
  },
  {
    name: "Col",
    key: "col",
    type: "number",
  },
  {
    name: "Gap (px)",
    key: "gap",
    type: "number",
  },
];

const bannerSettings2: SettingItem<SettingsNames>[] = [
  {
    name: "Autoplay",
    key: "autoplay",
    type: "checkbox",
  },
  {
    name: "Autoplay Delay (ms)",
    key: "delay",
    type: "number",
  },
];

const FormForBannerCollections: FC<Props> = ({ choosenItem, onUpdate }) => {
  const choosenAllImages = useSelector(
    (state: RootState) => state.showImagePopUp.images
  );
  const [active, setActive] = useState<boolean>(choosenItem.active);
  const [image, setImage] = useState<Image[]>([]);
  const [images, setImages] = useState<Image[]>([]);

  const dispatch = useDispatch();

  const [panel, setPanel] = useState<Panel>("main");
  const [containerType, setContainerType] = useState<"full" | "box">(
    (choosenItem as any)?.data?.settings?.containerType || "box"
  );
  const [settings, setSettings] = useState<SettingItems<SettingsNames>>(
    (choosenItem as any)?.data?.settings as any
  );

  useEffect(() => {
    setImages(choosenItem.data?.images || []);
    setImage(
      (choosenItem.data as any)?.image ? [(choosenItem.data as any)?.image] : []
    );
  }, [choosenItem]);

  const handleTitle = (e: React.ChangeEvent<HTMLInputElement>, image: any) => {
    setImages((pImages) => {
      return pImages.map((item) => {
        const copyItem = { ...item };
        if (item.image === image?.image) {
          copyItem.title = e.target.value;
        }
        return copyItem;
      });
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const widgetObj = {
      name: choosenItem.name,
      active: active,
      data: {
        images: images,
        image: image[0],
        settings: {
          ...settings,
          containerType: containerType,
        },
      },
      wType: choosenItem.wType,
      id: choosenItem.id,
    };

    // const actionObj = {
    //     widget: widgetObj,
    //     pTitle: 'home_page'
    // }

    // dispatch(handlePageWidget(actionObj))

    onUpdate(widgetObj);
  };
  const handleRemoveImage = (index: any) => {
    const filterImages = images.filter((_, i) => i !== index);
    setImages(filterImages);
  };

  const handleLinkData = (data: LinkData, image?: any) => {
    setImages((pImages) => {
      return pImages.map((item) => {
        const copyItem = { ...item };
        if (item.image === image?.image) {
          copyItem.link = data;
        }
        return copyItem;
      });
    });
  };

  const handleImageSelector = (index?: number) => {
    dispatch(
      setGlobalPopupElement(
        <ImagePopUpV2
          single={true}
          onUpdate={(images) => {
            const arrayImages = images.map((item: any) => {
              return {
                image: item.path,
                title: item.title,
                link: {} as LinkData,
              };
            });

            // setImages(prev => {
            //     return prev.concat(arrayImages);
            // })

            setImage([arrayImages[0]]);

            dispatch(setGlobalPopupElement(null));
          }}
          onClose={() => {
            dispatch(setGlobalPopupElement(null));
          }}
        />
      )
    );
  };

  const handleImagesSelector = (index?: number) => {
    dispatch(
      setGlobalPopupElement(
        <ImagePopUpV2
          single={index !== undefined}
          onUpdate={(images) => {
            const arrayImages = images.map((item: any) => {
              return {
                image: item.path,
                title: item.title,
                link: {} as LinkData,
              };
            });

            // setImages(prev => {
            //     return prev.concat(arrayImages);
            // })

            if (index === undefined) {
              setImages((prev) => {
                return prev.concat(arrayImages);
              });
            } else {
              setImages((prev) => {
                const copyPrev = [...prev];
                copyPrev[index] = {
                  ...copyPrev[index],
                  image: arrayImages[0].image,
                };
                return copyPrev;
              });
            }

            dispatch(setGlobalPopupElement(null));
          }}
          onClose={() => {
            dispatch(setGlobalPopupElement(null));
          }}
        />
      )
    );
  };

  return (
    <form onSubmit={handleSubmit} className="mainSliderForm__container">
      <h1 className="title capitalize">{choosenItem.name}</h1>

      {/* panels switch */}
      <div className="flex flex-col md:flex-row border border-black/5 border-b-none mt-0">
        {panels.map((p) => (
          <button
            key={p.name}
            type="button"
            className={`flex-1 py-2 text-sm font-light tracking-wide text-center border-b-2 focus:outline-none ${
              panel === p.name
                ? "border-green-500 text-black"
                : "text-black/50 hover:text-black/70"
            }`}
            onClick={() => setPanel(p.name)}
          >
            {p.title}
          </button>
        ))}
      </div>

      {panel === "main" ? (
        <div>
          <div className="py-4 border-b border-black/5">
            <ImageSortWithTitle
              data={image}
              title={`GIF Image (${settings?.width ?? 794}x${
                settings?.height ?? 712
              })`}
              setData={setImage}
            />
            {image.map((image: any, index: number) => {
              return (
                <ImageItem
                  key={index}
                  image={image}
                  index={index}
                  setImages={setImage}
                  onRemove={() => setImage([])}
                  handleTitle={(e) => {
                    setImage((prev) => {
                      const copyPrev = [...prev];
                      copyPrev[index] = {
                        ...copyPrev[index],
                        title: e.target.value,
                      };
                      return copyPrev;
                    });
                  }}
                />
              );
              // return(
              //     <div key={image?.name} className='mb-4 last:mb-0 divide-y mt-2 shadow-lg p-2 bg-white rounded-md relative'>
              //         {/* <img className='rounded-md mb-1' src={process.env.REACT_APP_IMAGE_URL+image?.image} alt="" /> */}
              //         <ImageContainerEditButton
              //             index={index}
              //             title={image?.title}
              //             imageUrl={process.env.REACT_APP_IMAGE_URL+image?.image}
              //             handleImageSelector={handleImageSelector}
              //         />
              //         <input className='mb-1' value={image?.title} type="text" placeholder='title :' onChange={(e) => handleTitle(e, image)}/>
              //         <LinkForm currnetLinkData={image?.link} onUpdate={handleLinkData} selectedImage={image}/>
              //         <div className='image__popup--close-btn' onClick={()=>handleRemoveImage(index)}>
              //             <CloseIcon />
              //         </div>
              //     </div>
              // )
            })}

            <ImageSelectorV2 onClick={handleImageSelector} />
          </div>

          <div className="py-4 border-b border-black/5">
            <ImageSortWithTitle
              data={images}
              title={`Slider images (${settings?.width ?? 909}x${
                settings?.height ?? 861
              })`}
              setData={setImages}
            />
            {images.map((image: any, index: number) => {
              return (
                <ImageItem
                  key={index}
                  image={image}
                  index={index}
                  setImages={setImages}
                  onRemove={() => handleRemoveImage(index)}
                  handleTitle={handleTitle}
                />
              );
              // return(
              //     <div key={image?.name} className='mb-4 last:mb-0 divide-y mt-2 shadow-lg p-2 bg-white rounded-md relative'>
              //         {/* <img className='rounded-md mb-1' src={process.env.REACT_APP_IMAGE_URL+image?.image} alt="" /> */}
              //         <ImageContainerEditButton
              //             index={index}
              //             title={image?.title}
              //             imageUrl={process.env.REACT_APP_IMAGE_URL+image?.image}
              //             handleImageSelector={handleImageSelector}
              //         />
              //         <input className='mb-1' value={image?.title} type="text" placeholder='title :' onChange={(e) => handleTitle(e, image)}/>
              //         <LinkForm currnetLinkData={image?.link} onUpdate={handleLinkData} selectedImage={image}/>
              //         <div className='image__popup--close-btn' onClick={()=>handleRemoveImage(index)}>
              //             <CloseIcon />
              //         </div>
              //     </div>
              // )
            })}

            <ImageSelectorV2 onClick={handleImagesSelector} />
          </div>
        </div>
      ) : panel === "settings" ? (
        <div>
          {/* image container full or box radio setting */}
          <div className="flex flex-col mb-2">
            <div>Image container: </div>
            <div className="[&>input]:!w-4 [&>input]:mx-2">
              <input
                type="radio"
                id="b-type-full"
                name="imageContainer"
                value="full"
                onChange={(e) =>
                  setContainerType(e.target.value as "full" | "box")
                }
                checked={containerType === "full"}
              />
              <label htmlFor="b-type-full">Full</label>

              <input
                type="radio"
                id="b-type-box"
                name="imageContainer"
                value="box"
                onChange={(e) =>
                  setContainerType(e.target.value as "full" | "box")
                }
                checked={containerType === "box"}
              />
              <label htmlFor="b-type-box">Box</label>
            </div>
          </div>

          <SettingFormItems
            settings={settings}
            settingItems={[
              ...bannerSettings,
              ...(settings?.isSlider ? bannerSettings2 : []),
            ]}
            onChange={(key, value) => {
              setSettings((prev) => ({
                ...prev,
                [key]: value,
              }));
            }}
          />
        </div>
      ) : null}
      {/* <ImageSelector /> */}

      {/* <ImageSelectorV2 onClick={handleImageSelector} /> */}
      <div>
        <button className="mainSliderForm__btn">save</button>
      </div>
    </form>
  );
};

export default FormForBannerCollections;
