import { FC } from "react";
import { Widget, widgetNames } from "../../widgetList/widgetList";
import FormForBanner from "./FormForBanner";
import FormForBannerCollections from "./FormForBannerCollections";
import FormForBanners from "./FormForBanners";
import FormForBestSellingProdcuts from "./FormForBestSellingProducts";
import FormForBarndList from "./FormForBrandList";
import FormForCategories from "./FormForCategories";
import FormForContentEditor from "./FormForContentEditor";
import FormForDoubleCollumPromotion from "./FormForDoubleCollumPromotion";
import FormForElectronicsApplience from "./FormForElectronicsApplience";
import FormForFilterProducts from "./FormForFilterProducts";
import FormForGridBanners from "./FormForGridBanners";
import FormForHighlightShop from "./FormForHighlightShop";
import FormForInDemand from "./FormForInDemand";
import FormForNewArrival from "./FormForNewArrival";
import FormForPopulerProduct from "./FormForPopulerProduct";
import FormForProductRow, { FomrForProductRow2 } from "./FormForProductRow";
import FormForProductSlider from "./FormForProductSlider";
import FormForProductsLimit from "./FormForProductsLimit";
import FormForRandomSizeBanners from "./FormForRandomSizeBanners";
import FormForReviewsSlider from "./FormForReviewsSlider";
import FormForSellerList from "./FormForSellerList";
import FormForSellerProfile from "./FormForSellerProfile";
import FormForServices from "./FormForServices";
import FormForSingleCollumPromotion from "./FormForSingleCollumPromotion";
import FormForSingleProductSlider from "./FormForSingleProductSlider";
import FromForSlider from "./FormForSlider";
import FormForSmallWideBanner from "./FormForSmallWideBanner";
import FormForThreeBannerProducts from "./FormForThreeBannerProducts";
import FormForThreeCollumBanner from "./FormForThreeCollum";
import FomrForTile from "./FormForTitle";
import FormForTodaysDeal from "./FormForTodaysDeal";
import FormForVideoSlider from "./FormForVideoSlider";
import FormForVideos from "./FormForVideos";
import FromForTrendingProducts from "./FromForTrendingProducts";

interface Props {
	choosenItem: Widget;
	onUpdate: (w: Widget) => void;
}

const WidgetForm: FC<Props> = ({ choosenItem, onUpdate }) => {
	return (
		<>
			{choosenItem.name === "slider" ? (
				<FromForSlider onUpdate={onUpdate} choosenItem={choosenItem} />
			) : choosenItem.name === "banner" ? (
				<FormForBanner onUpdate={onUpdate} choosenItem={choosenItem} />
			) : choosenItem.name === "3-collum-images" ? (
				<FormForThreeCollumBanner
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "populer-product" ? (
				<FormForPopulerProduct
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "title" ? (
				<FomrForTile onUpdate={onUpdate} choosenItem={choosenItem} />
			) : choosenItem.name === "categories" ? (
				<FormForCategories
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "one-collum-add" ? (
				<FormForSingleCollumPromotion
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "two-collum-add" ? (
				<FormForDoubleCollumPromotion
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "product-row" ? (
				<FormForProductRow
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "brand-list" ? (
				<FormForBarndList
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "seller-list" ? (
				<FormForSellerList
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "new-arrival" ? (
				<FormForNewArrival
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "product-slider" ? (
				<FormForProductSlider
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "todays-deal" ? (
				<FormForTodaysDeal
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "highlighted-shop" ? (
				<FormForHighlightShop
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "best-selling" ? (
				<FormForBestSellingProdcuts
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "single-product" ? (
				<FormForSingleProductSlider
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "electronics-applience" ? (
				<FormForElectronicsApplience
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "seller-profile" ? (
				<FormForSellerProfile
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "content-editor" ? (
				<FormForContentEditor
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "product-row-limit" ? (
				<FormForProductsLimit
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "in-demand" ? (
				<FormForInDemand
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.lastVisitedProducts ? (
				<FomrForProductRow2
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.infiniteProductRow ? (
				<FormForProductsLimit
					widgetName={widgetNames.infiniteProductRow}
					title="Infinite Product Row"
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.smallWideBanner ? (
				<FormForSmallWideBanner
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.banners ? (
				<FormForBanners onUpdate={onUpdate} choosenItem={choosenItem} />
			) : choosenItem.name === widgetNames.trendingProducts ? (
				<FromForTrendingProducts
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.filterProducts ? (
				<FormForFilterProducts
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.threeBannerProducts ? (
				<FormForThreeBannerProducts
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.randomSizeBanners ? (
				<FormForRandomSizeBanners
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.services ? (
				<FormForServices
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.gridBanner ? (
				<FormForGridBanners
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.videoSlider ? (
				<FormForVideoSlider
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.videos ? (
				<FormForVideos onUpdate={onUpdate} choosenItem={choosenItem} />
			) : choosenItem.name === widgetNames.bannerCollection ? (
				<FormForBannerCollections
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.reviewsSlider ? (
				<FormForReviewsSlider
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : null}
		</>
	);
};

export default WidgetForm;
