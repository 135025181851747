import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { Image, Logical, PageCategory, Widget } from '../../../widgetList/widgetList'
import { LinkData } from '../LinkForm'
import TopPanel, { Panel } from '../TopPanel'
import ProductsDefaultSettings from '../ProductsDefaultSettings'
import ProductManageForm from '../../ProductManageForm'
import { setGlobalPopupElement, setGlobalProductPopupElement } from '../../../features/popupSlice'
import PopupWrapper from '../../PopupWrapper'
import ImageItem from '../../ImageItem'

interface ProductItemsType {
    custom?: number [];
    logical?: Logical;
}

interface Props {
    choosenItem: any // type: Widget
    onUpdate: (w: Widget) => void
}

const FromForTrendingProducts:FC<Props> = ({choosenItem, onUpdate}) => {
    const dispatch = useDispatch();

    const choosenAllImages = useSelector((state: RootState) => state.showImagePopUp.images)
    const customProductIds = useSelector((state: RootState) => state.logicBuilder.setting)

    const [active, setActive] = useState<boolean>(choosenItem.active)

    const [title, setTitle] = useState('')
    const [link, setLink] = useState<LinkData>({} as LinkData)
    const [sourceType, setSourceType] = useState('custom')
    const [isSlider, setIsSlider] = useState<boolean>(!!choosenItem.data?.isSlider)
    const [leftProducts, setLeftProducts] = useState<ProductItemsType>(choosenItem?.data?.leftProducts || {} as ProductItemsType)
    const [mainProducts, setMainProducts] = useState<ProductItemsType>(choosenItem?.data?.mainProducts || {} as ProductItemsType)
    const [featuredImage, setFeaturedImage] = useState<Image>(choosenItem?.data?.featuredImage || {
        image: '',
        title: '',
        link: {} as LinkData,
    })

    const [selectedCampaign, setSelectedCampaign] = useState<PageCategory | null>(null);

    const [panel, setPanel] = useState<Panel>('main');

    const [colProductLimit, setColProductLimit] = useState<number>(choosenItem?.data?.settings?.productLimit)

    const [settings, setSettings] = useState<any>(choosenItem?.data?.settings as any);

    useEffect(()=> {
        const customLenght: any = choosenItem?.data?.setting?.custom?.length
        if(customLenght > 0){
            setSourceType('custom')
        }else {
            setSourceType('logical')
        }
    },[choosenItem])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault()

        const widgetObj: any = {
            name: choosenItem.name,
            active: active,
            data : {
                selectedCampaign: selectedCampaign || choosenItem?.data?.selectedCampaign || null,
                leftProducts: leftProducts,
                mainProducts: mainProducts,
                featuredImage: featuredImage,
                settings: {
                    productLimit: colProductLimit,
                    ...settings,
                }
            },
            wType: choosenItem.wType,
            id: choosenItem.id,
        }
        
        onUpdate(widgetObj)
    }
    return (
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <h1 className='title capitalize'>{choosenItem.name}</h1>

            <TopPanel 
                panel={panel}
                onChange={name => setPanel(name)}
            />
            {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
            
            {
                panel === 'main' ? (
                    <div
                        className="py-4 [&>*]:mb-4"
                    >
                        {/* left slider products */}
                        <button
                            className='text-gray-500 px-3 py-2 rounded-md shadow-lg bg-gray-100 hover:bg-white w-full'
                            onClick={(e) => {
                                e.preventDefault();

                                dispatch(setGlobalProductPopupElement(
                                    <PopupWrapper
                                        onClose={() => {
                                            dispatch(setGlobalProductPopupElement(null))
                                        }}
                                    >
                                        <ProductManageForm title='Manage Left Slider Products'
                                            logical={leftProducts?.logical}
                                            custom={leftProducts?.custom}
                                            onUpdate={(logical, custom) => {
                                                setLeftProducts({
                                                    logical: logical || undefined,
                                                    custom: custom || [],
                                                });

                                                dispatch(setGlobalProductPopupElement(null))
                                            }}
                                        />
                                    </PopupWrapper>
                                ));
                            }}
                        >Manage Left Slider Products</button>

                        {/* right slider products */}
                        <button
                            className='text-gray-500 px-3 py-2 rounded-md shadow-lg bg-gray-100 hover:bg-white w-full'
                            onClick={(e) => {
                                e.preventDefault();

                                dispatch(setGlobalProductPopupElement(
                                    <PopupWrapper
                                        onClose={() => {
                                            dispatch(setGlobalProductPopupElement(null))
                                        }}
                                    >
                                        <ProductManageForm title='Manage Main Products'
                                            logical={mainProducts?.logical}
                                            custom={mainProducts?.custom}
                                            onUpdate={(logical, custom) => {
                                                setMainProducts({
                                                    logical: logical || undefined,
                                                    custom: custom || [],
                                                });

                                                dispatch(setGlobalProductPopupElement(null))
                                            }}
                                        />
                                    </PopupWrapper>
                                ))
                            }}
                        >Manage Main Products</button>

                        <ImageItem 
                            title='Featured Image'
                            image={featuredImage}
                            setImages={(images) => {}}
                            setImage={(image) => {
                                setFeaturedImage(image);
                            }}
                            handleTitle={(e, image) => {
                                const title = e.target.value;
                                setFeaturedImage({
                                    ...featuredImage,
                                    title: title,
                                })
                            }}
                            onRemove={() => {
                                setFeaturedImage({
                                    image: '',
                                    title: '',
                                    link: {} as LinkData,
                                })
                            }}
                        />
                    </div>
                ) : (
                    <div className='flex flex-col items-start gap-4 mt-3 pt-2 border-t'>
                        <div className='flex items-center gap-4'>
                            <div>Product Limit : </div>
                            <input className='w-20' type="number" value={colProductLimit} onChange={(e)=> setColProductLimit(Number(e.target.value))}/>
                        </div>

                        <ProductsDefaultSettings 
                            settings={settings}
                            onChange={(key, value) => {
                                setSettings({
                                    ...settings,
                                    [key]: value,
                                })
                            }}
                        />
                    </div>
                )
            }

            <div>
                <button className='mainSliderForm__btn'>save</button>
            </div>
        </form>
    )
}

export default FromForTrendingProducts