import React from "react";
import Header from "../global/Header";
import Footer from "../global/Footer";
import SideBar from "../global/Sidebar";
import ImagePopUP from "../ImagePopUp";

import "./layout.css";

import type { RootState } from "../../app/store";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleSideBar,
  openSideBar,
  closeSideBar,
} from "../../features/showSideBar/showSideBar";
import ImageDetails from "../ImageDetails";
import ContentEditor from "../ContentEditor";
import CategorySearchV2 from "../CategorySearchV2";
import ChangeLogPopup from "../ChangeLogPopup";
import { PageLoader } from "../PageLoader";

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const showSideBar = useSelector(
    (state: RootState) => state.showSideBar.value
  );
  const showImagePopUp = useSelector(
    (state: RootState) => state.showImagePopUp.value
  );
  const showImageDetails = useSelector(
    (state: RootState) => state.imageDetails.value
  );
  const showContentEditor = useSelector(
    (state: RootState) => state.contentEditor.value
  );
  const showCategoryPopup = useSelector(
    (state: RootState) => state.popupControl.categoryPopup.active
  );
  const globalPopupElement = useSelector(
    (state: RootState) => state.popupControl.globalPopup
  );
  const globalProductPopup = useSelector(
    (state: RootState) => state.popupControl.globalProductPopup
  );
  const loading = useSelector(
    (state: RootState) => state.popupControl.loading || state.allPages.loading
  );

  const rollbackLoading = useSelector(
    (state: RootState) => state.pageId.rollbackLoading
  );

  const dispatch = useDispatch();

  return (
    <div className="layout__container">
      <div className={`layout__row--one ${showSideBar ? "w-[20%]" : "w-[6%]"}`}>
        <SideBar />
      </div>
      <div
        className={`layout__row--two ${
          showSideBar ? "w-[80%] ml-[20%]" : "w-[94%] ml-[6%]"
        }`}
      >
        <header>
          <Header />
        </header>
        <main>{children}</main>
        {/* <footer>
                    <Footer />
                </footer> */}
      </div>
      {showImagePopUp && <ImagePopUP />}
      {showImageDetails && <ImageDetails />}
      {showContentEditor && <ContentEditor />}
      {showCategoryPopup && <CategorySearchV2 />}
      {globalProductPopup}
      {globalPopupElement}
      {loading && <PageLoader />}

      {rollbackLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-md">
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
            <div className="text-center mt-2">Loading...</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;
