import React from "react";
import "./campaign.css";

const Campaign: React.FC = () => {
  return (
    <div className="campaign__container">
      <div className="campaign__wrapper">
        <img src="/img/widgets/banner.png" alt="" />
      </div>
    </div>
  );
};

export const SmallWideBanner: React.FC = () => {
  return (
    <div className="campaign__container">
      <div className="campaign__wrapper">
        <img src="/img/widgets/two-collum-wide.png" alt="" />
      </div>
    </div>
  );
};

export const TrendingProducts: React.FC = () => {
  return (
    <div className="campaign__container">
      <div className="campaign__wrapper">
        <img src="/img/widgets/trending-product.png" alt="" />
      </div>
    </div>
  );
};

export const FilterProducts: React.FC = () => {
  return (
    <div className="campaign__container">
      <div className="campaign__wrapper">
        <img src="/img/widgets/filter-products.png" alt="" />
      </div>
    </div>
  );
};

export const RandomBanners: React.FC = () => {
  return (
    <div className="campaign__container">
      <div className="campaign__wrapper">
        <img src="/img/widgets/random-banners.png" alt="" />
      </div>
    </div>
  );
};

export const ThreeBannersProducts: React.FC = () => {
  return (
    <div className="campaign__container">
      <div className="campaign__wrapper">
        <img src="/img/widgets/three-banner-products.png" alt="" />
      </div>
    </div>
  );
};

export const ServicesBanner: React.FC = () => {
  return (
    <div className="campaign__container">
      <div className="campaign__wrapper">
        <img src="/img/widgets/services.jpg" alt="" />
      </div>
    </div>
  );
};

export const GridBanner: React.FC = () => {
  return (
    <div className="campaign__container">
      <div className="campaign__wrapper">
        <img src="/img/widgets/grid-banner.jpg" alt="" />
      </div>
    </div>
  );
};

export const VideoSlider: React.FC = () => {
  return (
    <div className="campaign__container">
      <div className="campaign__wrapper">
        <img src="/img/widgets/video-slider.jpg" alt="" />
      </div>
    </div>
  );
};

export const BannerCollection: React.FC = () => {
  return (
    <div className="campaign__container">
      <div className="campaign__wrapper">
        <img src="/img/widgets/banner-collection.jpg" alt="" />
      </div>
    </div>
  );
};

export const ReviewsSlider: React.FC = () => {
  return (
    <div className="campaign__container">
      <div className="campaign__wrapper">
        <img src="/img/widgets/reviews.jpg" alt="" />
      </div>
    </div>
  );
};

export default Campaign;
