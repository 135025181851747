import { useState, useEffect } from "react"

import { Logical, Widget } from "../widgetList/widgetList"
import { CustomBuilderV2 } from "./LogicBuilder/CustomBuilder"
import { LogicalBuilderV2 } from "./LogicBuilder/LogicalBuilder"

interface Props {
    title?: string;
    logical?: Logical;
    custom?: number [];
    onUpdate: (logical: Logical | null, custom: number [] | null) => void;
}

const ProductManageForm = ({
    title,
    logical, 
    custom, 
    onUpdate,
}: Props) => {
    const [sourceType, setSourceType] = useState(!!custom?.length ? 'custom' : 'logical')

    return (
        <div
            className="p-4 bg-white w-[80vw] max-w-[500px]"
        >
            {title && <div className='text-lg font-semibold mb-4'>{title}</div>}
            <div className='flex flex-col items-start gap-4'>
                <div>Source type : </div>
                <div className='flex items-center gap-4'>
                    <div onClick={()=> setSourceType('logical')} className='flex items-center gap-2 cursor-pointer'>
                        <div className={`w-5 h-5 rounded-md ${sourceType === 'logical' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                        <div>Logical</div>
                    </div>
                    <div onClick={()=> setSourceType('custom')} className='flex items-center gap-2 cursor-pointer'>
                        <div className={`w-5 h-5 rounded-md ${sourceType === 'custom' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                        <div>Custom</div>
                    </div>
                </div>
            </div>

            {sourceType === 'custom'
                ? <CustomBuilderV2 custom={custom} 
                    onUpdate={(custom) => {
                        onUpdate(null, custom)
                    }}
                />
                : <LogicalBuilderV2 logical={logical}
                    onUpdate={(logical) => {
                        onUpdate(logical, null)
                    }}
                />
            }
        </div>
    )
}

export default ProductManageForm;