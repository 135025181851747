export const adminUserProfileUrl = process.env.REACT_APP_USER_PROFILE_URL || "";

export const adminTokenCookieName =
  process.env.REACT_APP_TOKEN_COOKIE_NAME || "anzaar-admin-token";

export const cookieSecret = {
  key: "secret-log",
  value: "bbt@2023",
};

export const logos = {
  faviconBlack: "/logos/fabicon.ico",
  faviconWhite: "/logos/fabicon.ico",
  fullLogoBlack: "/logos/logo.png",
  iconOng: "/logos/logo.png",
  iconWhite: "/logos/logo.png",
  logoBanglaBlack: "/logos/logo.png",
  logoWhite: "/logos/logo.png",
};

export const envs = {
  domainUrl: process.env.REACT_APP_COOKIE_URL,
  baseApi: process.env.REACT_APP_BASE_URL,
  apiVersion: process.env.NEXT_PUBLIC_API_VERSION,
  homeUrl: process.env.REACT_APP_LOGIN_REDIRECT,
  imageBasePath: process.env.REACT_APP_IMAGE_URL,
  frontendApiUrl: process.env.REACT_APP_FRONTEND_API_URL,
  adminUrl: process.env.REACT_APP_LOGIN_REDIRECT,
};
