import { useDispatch } from "react-redux";
import { setGlobalPopupElement } from "../features/popupSlice";
import { Image } from "../widgetList/widgetList";
import ImagePopUpV2 from "./ImagePopUpV2";
import { ImageContainerEditButton } from "./components";
import CloseIcon from "./icons/CloseIcon";
import LinkForm, { LinkData } from "./widgetFrom/LinkForm";

const ImageItem = ({
  title,
  image,
  index,
  setImages,
  setImage,
  onRemove,
  handleTitle = () => {},
}: {
  title?: string;
  image: Image;
  index?: number;
  setImages: React.Dispatch<React.SetStateAction<Image[]>>;
  setImage?: (image: Image) => void;
  onRemove?: () => void;
  handleTitle?: (
    e: React.ChangeEvent<HTMLInputElement>,
    image: Image | undefined
  ) => void;
}) => {
  const dispatch = useDispatch();

  const handleImageSelector = (index?: number) => {
    dispatch(
      setGlobalPopupElement(
        <ImagePopUpV2
          single={index !== undefined}
          onUpdate={(images) => {
            const arrayImages = images.map((item: any) => {
              return {
                image: item.path,
                title: item.title,
                link: {} as LinkData,
              };
            });

            // setImages(prev => {
            //     return prev.concat(arrayImages);
            // })

            if (index === undefined) {
              setImages((prev) => {
                return prev.concat(arrayImages);
              });
              if (setImage) {
                setImage(arrayImages[0]);
              }
            } else {
              setImages((prev) => {
                const copyPrev = [...prev];
                copyPrev[index] = {
                  ...copyPrev[index],
                  image: arrayImages[0].image,
                };
                return copyPrev;
              });
            }

            dispatch(setGlobalPopupElement(null));
          }}
          onClose={() => {
            dispatch(setGlobalPopupElement(null));
          }}
        />
      )
    );
  };

  const handleLinkData = (data: LinkData, image?: any) => {
    setImages((pImages) => {
      return pImages.map((item) => {
        const copyItem = { ...item };
        if (item.image === image?.image) {
          copyItem.link = data;
        }
        return copyItem;
      });
    });

    if (setImage) {
      setImage({
        ...image,
        link: data,
      });
    }
  };

  return (
    <div className="mb-4 last:mb-0 divide-y mt-2 shadow-lg p-2 bg-white rounded-md relative">
      {title && <div className="text-lg mb-4">{title}</div>}
      {/* <img className='rounded-md mb-1' src={process.env.REACT_APP_IMAGE_URL+image?.image} alt="" /> */}
      <ImageContainerEditButton
        index={index}
        title={image?.title}
        imageUrl={
          !!image?.image ? process.env.REACT_APP_IMAGE_URL + image?.image : ""
        }
        handleImageSelector={handleImageSelector}
      />
      <input
        className="mb-1"
        value={image?.title}
        type="text"
        placeholder="title :"
        onChange={(e) => handleTitle(e, image)}
      />
      <LinkForm
        currnetLinkData={image?.link}
        onUpdate={handleLinkData}
        selectedImage={image}
      />
      {!!image?.image && (
        <div className="image__popup--close-btn" onClick={onRemove}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

export default ImageItem;
