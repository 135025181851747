import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { ProductItemGet } from "../../../types/product";
import { Logical, PageCategory, Widget } from "../../../widgetList/widgetList";
import ProductSearch from "../../ProductSearch";
import SettingFormItems, { SettingItem } from "../../SettingFormItems";
import CloseIcon from "../../icons/CloseIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import PlusIcon from "../../icons/PlusIcon";
import BackgroundSettings from "../BackgroundSettings";
import LinkForm, { LinkData } from "../LinkForm";
import TopPanel, { Panel } from "../TopPanel";
import FormInputItem from "../../FormInputItem";

type SettingsNames =
  | "isSlider"
  | "width"
  | "height"
  | "objectFit"
  | "col"
  | "autoplay"
  | "delay"
  | "gap";

const bannerSettings: SettingItem<SettingsNames>[] = [
  {
    name: "Is Slider",
    key: "isSlider",
    type: "checkbox",
  },
  {
    name: "Width",
    key: "width",
    type: "number",
  },
  {
    name: "Height",
    key: "height",
    type: "number",
  },
  {
    name: "Object Fit",
    key: "objectFit",
    type: "select",
    options: [
      { name: "Contain", value: "contain" },
      { name: "Cover", value: "cover" },
    ],
  },
  {
    name: "Col",
    key: "col",
    type: "number",
  },
  {
    name: "Gap (px)",
    key: "gap",
    type: "number",
  },
];

const bannerSettings2: SettingItem<SettingsNames>[] = [
  {
    name: "Autoplay",
    key: "autoplay",
    type: "checkbox",
  },
  {
    name: "Autoplay Delay (ms)",
    key: "delay",
    type: "number",
  },
];

interface ProductItemsType {
  custom?: number[];
  logical?: Logical;
}

interface TabType {
  name: string;
  logo?: string;
  src?: string;
  link?: LinkData;
  products?: ProductItemsType;
  product?: ProductItemGet;
  customLink?: string;
  outboundLink?: string | ProductItemGet;
  linkType?: "product" | "custom";
}

interface Props {
  choosenItem: any; // type: Widget
  onUpdate: (w: Widget) => void;
}

const FormForVideos: FC<Props> = ({ choosenItem, onUpdate }) => {
  const dispatch = useDispatch();

  const [active, setActive] = useState<boolean>(choosenItem.active);

  const [title, setTitle] = useState(choosenItem?.data?.title || "");

  const [videoTabs, setVideoTabs] = useState<TabType[]>(
    choosenItem?.data?.tabs || ([] as TabType[])
  );

  const [popupElement, setPopupElement] = useState<JSX.Element | null>(null);

  const [selectedCampaign, setSelectedCampaign] = useState<PageCategory | null>(
    null
  );

  const [panel, setPanel] = useState<Panel>("main");

  const [colProductLimit, setColProductLimit] = useState<number>(
    choosenItem?.data?.settings?.productLimit
  );
  const [containerType, setContainerType] = useState<"full" | "box">(
    (choosenItem as any)?.data?.settings?.containerType || "box"
  );
  const [settings, setSettings] = useState<any>(
    choosenItem?.data?.settings as any
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const widgetObj: any = {
      name: choosenItem.name,
      active: active,
      data: {
        selectedCampaign:
          selectedCampaign || choosenItem?.data?.selectedCampaign || null,
        tabs: videoTabs,
        title: title,
        settings: {
          productLimit: colProductLimit,
          ...settings,
          containerType: containerType,
        },
      },
      wType: choosenItem.wType,
      id: choosenItem.id,
    };

    onUpdate(widgetObj);
  };

  const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const VideoTab = ({
    index,
    tab = {} as TabType,
    onSubmit,
  }: {
    index: number;
    tab?: TabType;
    onSubmit: (tab: TabType) => void;
  }) => {
    const [name, setName] = useState<string>(tab.name);
    const [videoSrc, setVideoSrc] = useState<string>(tab.src || "");
    const [linkType, setLinkType] = useState<"product" | "custom">(
      tab.linkType || "product"
    );
    const [product, setProduct] = useState<ProductItemGet | undefined>(
      tab.linkType === "product"
        ? (tab.outboundLink as ProductItemGet)
        : undefined
    );

    const [customLink, setCustomLink] = useState<string>(
      tab.linkType === "custom" ? (tab.outboundLink as string) : ""
    );

    const link = linkType === "product" ? product : customLink;

    const handleSubmit = (e: any) => {
      e.preventDefault();

      onSubmit({
        name,
        src: videoSrc,
        outboundLink: link,
        linkType,
      });
    };

    return (
      <div
        key={index}
        className="py-4 w-full min-h-[100%] absolute left-0 top-0 z-50 bg-white !block !px-0"
      >
        {/* close button */}
        <button
          type="button"
          className="absolute right-0 top-0 z-50 cursor-pointer p-1 rounded-full border border-red-400 bg-red-400"
          onClick={() => {
            setPopupElement(null);
          }}
        >
          <CloseIcon />
        </button>

        <div className="flex items-center justify-between gap-4 pb-2">
          <h3 className="underline pb-1">Tab {index + 1}</h3>
        </div>
        <div className="flex items-center gap-4">
          <div className="capitalize tracking-wide">Name:</div>
          <input
            value={name}
            onChange={(e) => {
              const title = e.target.value;
              setName(title);
            }}
            type="text"
            placeholder="type here...."
          />
        </div>

        {/* video src input */}
        <div className="mt-4">
          <div className="capitalize tracking-wide">Video Src:</div>
          <input
            value={videoSrc}
            onChange={(e) => {
              const src = e.target.value;
              setVideoSrc(src);
            }}
            type="text"
            placeholder="type here...."
          />
        </div>

        <div className="flex gap-2 mt-4 border-b">
          <FormInputItem
            label={"Product Link"}
            value={linkType === "product"}
            type="checkbox"
            onChange={(e) => {
              setLinkType("product");
            }}
          />
          <FormInputItem
            label="Custom Link"
            value={linkType === "custom"}
            placeholder="5"
            type="checkbox"
            onChange={(e) => {
              setLinkType("custom");
            }}
          />
        </div>

        {/* product search here */}

        {linkType === "product" ? (
          <ProductSearch
            choosenProducts={product ? [product] : []}
            setChoosenProduct={(product) => {
              setProduct(product);
            }}
          />
        ) : (
          <div className="mt-4">
            <div className="capitalize tracking-wide">Custom Link:</div>
            <input
              value={customLink}
              onChange={(e) => {
                const src = e.target.value;
                setCustomLink(src);
              }}
              type="text"
              placeholder="Add custom Link ..."
            />
          </div>
        )}

        <div className="my-2 w-full"></div>

        {/* submit btn */}
        <div className="mt-4">
          <button
            onClick={handleSubmit}
            className="w-full py-3 px-2 bg-yellow-400"
          >
            Submit
          </button>
        </div>
      </div>
    );
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="mainSliderForm__container relative"
    >
      <h1 className="title capitalize">{choosenItem.name}</h1>

      <TopPanel panel={panel} onChange={(name) => setPanel(name)} />
      {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}

      {popupElement === null && (
        <>
          {panel === "main" ? (
            <div className="py-4 [&>*]:mb-4">
              <div>
                {videoTabs.map((tab, index) => {
                  return (
                    <div
                      key={index}
                      className="p-2 py-4 rounded-md shadow-lg w-full flex justify-between items-start gap-2"
                    >
                      <div>
                        <p>Name: {tab.name}</p>
                        {tab.logo && (
                          <div className="flex items-center gap-4">
                            <div className="capitalize tracking-wide">
                              Logo:
                            </div>
                            <img
                              className="w-[60px] h-[40px] object-contain"
                              src={process.env.REACT_APP_IMAGE_URL + tab.logo}
                              alt=""
                            />
                          </div>
                        )}
                        {tab.link?.url && (
                          <p>
                            Link:
                            <span className="underline opacity-90 ml-2">
                              /{tab.link?.url}
                            </span>
                          </p>
                        )}

                        {tab.src && (
                          <div className="flex items-center gap-4">
                            <div className="capitalize tracking-wide">Src:</div>
                            <input
                              value={tab.src}
                              onChange={(e) => {
                                const src = e.target.value;
                                const newTabs = [...videoTabs];
                                newTabs[index].src = src;
                                setVideoTabs(newTabs);
                              }}
                              type="text"
                              readOnly
                              placeholder="type here...."
                            />
                          </div>
                        )}

                        {tab.linkType === "product" ? (
                          (() => {
                            const product = tab.outboundLink as ProductItemGet;

                            return (
                              <div className="">
                                <div className="flex items-center gap-4">
                                  <img
                                    className="w-[60px] h-[40px] object-contain"
                                    src={
                                      process.env.REACT_APP_IMAGE_URL +
                                        product?.thumbnail || ""
                                    }
                                    alt=""
                                  />
                                  <p className="capitalize tracking-wide">
                                    {product?.name || ""}
                                  </p>
                                </div>
                              </div>
                            );
                          })()
                        ) : (
                          <div className="flex items-center gap-2 mt-2">
                            <div className="capitalize tracking-wide">
                              Link:
                            </div>
                            <input
                              value={tab.outboundLink as string}
                              type="text"
                              readOnly
                              placeholder="type here...."
                            />
                          </div>
                        )}

                        {/* {tab.product && (
                          <div className="">
                            <div className="flex items-center gap-4">
                              <img
                                className="w-[60px] h-[40px] object-contain"
                                src={
                                  process.env.REACT_APP_IMAGE_URL +
                                  tab.product?.thumbnail
                                }
                                alt=""
                              />
                              <p className="capitalize tracking-wide">
                                {tab.product?.name}
                              </p>
                            </div>
                          </div>
                        )} */}
                      </div>
                      <div className="flex flex-col gap-2">
                        <button
                          className="text-gray-500 px-2 py-1 rounded-md border border-gray-400 bg-gray-100 hover:bg-white"
                          onClick={(e) => {
                            e.preventDefault();

                            setPopupElement(
                              <VideoTab
                                index={index}
                                tab={tab}
                                onSubmit={(tab) => {
                                  const newTabs = [...videoTabs];
                                  newTabs[index] = tab;

                                  setVideoTabs(newTabs);

                                  setPopupElement(null);
                                }}
                              />
                            );
                          }}
                        >
                          Edit
                        </button>

                        <button
                          className="text-red-500 px-2 py-1 rounded-md border border-red-400 bg-red-100 hover:bg-white"
                          onClick={(e) => {
                            e.preventDefault();

                            const newTabs = [...videoTabs];
                            newTabs.splice(index, 1);

                            setVideoTabs(newTabs);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  );
                })}

                {/* new add button */}
                <button
                  className="text-gray-500 mt-4 px-3 py-2 rounded-md shadow-lg bg-gray-100 hover:bg-white w-full flex gap-4"
                  onClick={(e) => {
                    e.preventDefault();

                    setPopupElement(
                      <VideoTab
                        index={videoTabs.length}
                        onSubmit={(tab) => {
                          setVideoTabs([...videoTabs, tab]);

                          setPopupElement(null);
                        }}
                      />
                    );
                  }}
                >
                  <PlusIcon /> Add {videoTabs.length > 0 ? "New" : "Video Tab"}
                </button>
              </div>
            </div>
          ) : (
            <div>
              {/* image container full or box radio setting */}
              <div className="flex flex-col mb-2">
                <div>Image container: </div>
                <div className="[&>input]:!w-4 [&>input]:mx-2">
                  <input
                    type="radio"
                    id="b-type-full"
                    name="imageContainer"
                    value="full"
                    onChange={(e) =>
                      setContainerType(e.target.value as "full" | "box")
                    }
                    checked={containerType === "full"}
                  />
                  <label htmlFor="b-type-full">Full</label>

                  <input
                    type="radio"
                    id="b-type-box"
                    name="imageContainer"
                    value="box"
                    onChange={(e) =>
                      setContainerType(e.target.value as "full" | "box")
                    }
                    checked={containerType === "box"}
                  />
                  <label htmlFor="b-type-box">Box</label>
                </div>
              </div>

              <SettingFormItems
                settings={settings}
                settingItems={[
                  ...bannerSettings,
                  ...(settings?.isSlider ? bannerSettings2 : []),
                ]}
                onChange={(key, value) => {
                  setSettings((prev: any) => {
                    if (!prev)
                      return {
                        [key]: value,
                      };

                    return {
                      ...prev,
                      [key]: value,
                    };
                  });
                }}
              />

              <BackgroundSettings
                settings={settings}
                onChange={(key, value) => {
                  setSettings((prev: any) => {
                    if (!prev)
                      return {
                        [key]: value,
                      };

                    return {
                      ...prev,
                      [key]: value,
                    };
                  });
                }}
              />
            </div>
          )}

          <div>
            <button className="mainSliderForm__btn">save</button>
          </div>
        </>
      )}

      {popupElement}
    </form>
  );
};

export default FormForVideos;
