import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { setGlobalPopupElement } from "../../../features/popupSlice";
import { Logical, PageCategory, Widget } from "../../../widgetList/widgetList";
import ImagePopUpV2 from "../../ImagePopUpV2";
import { ImageContainerEditButton } from "../../components";
import CloseIcon from "../../icons/CloseIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import PlusIcon from "../../icons/PlusIcon";
import LinkForm, { LinkData } from "../LinkForm";
import TopPanel, { Panel } from "../TopPanel";

interface ProductItemsType {
	custom?: number[];
	logical?: Logical;
}

interface CustomerType {
	name: string;
	subtitle?: string;
	avatar?: string;
}

interface TabType {
	title: string;
	description?: string;
	thumbnail?: string;
	link?: LinkData;
	customer?: CustomerType;
}

interface Props {
	choosenItem: any; // type: Widget
	onUpdate: (w: Widget) => void;
}

const FormForReviewsSlider: FC<Props> = ({ choosenItem, onUpdate }) => {
	const dispatch = useDispatch();

	const [active, setActive] = useState<boolean>(choosenItem.active);

	const [title, setTitle] = useState(choosenItem?.data?.title || "");

	const [reviewsTabs, setReviewsTabs] = useState<TabType[]>(
		choosenItem?.data?.tabs || ([] as TabType[])
	);

	const [popupElement, setPopupElement] = useState<JSX.Element | null>(null);

	const [selectedCampaign, setSelectedCampaign] =
		useState<PageCategory | null>(null);

	const [panel, setPanel] = useState<Panel>("main");

	const [colProductLimit, setColProductLimit] = useState<number>(
		choosenItem?.data?.settings?.productLimit
	);

	const [settings, setSettings] = useState<any>(
		choosenItem?.data?.settings as any
	);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const widgetObj: any = {
			name: choosenItem.name,
			active: active,
			data: {
				selectedCampaign:
					selectedCampaign ||
					choosenItem?.data?.selectedCampaign ||
					null,
				tabs: reviewsTabs,
				title: title,
				settings: {
					productLimit: colProductLimit,
					...settings,
				},
			},
			wType: choosenItem.wType,
			id: choosenItem.id,
		};

		onUpdate(widgetObj);
	};

	const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
	};

	const ProductTab = ({
		index,
		tab = {} as TabType,
		onSubmit,
	}: {
		index: number;
		tab?: TabType;
		onSubmit: (tab: TabType) => void;
	}) => {
		const [title, setTitle] = useState<string>(tab.title);
		const [description, setDescription] = useState<string>(
			tab.description || ""
		);
		const [thumbnail, setThumbnail] = useState<string>(tab.thumbnail || "");
		const [link, setLink] = useState<LinkData>(
			tab.link || ({} as LinkData)
		);
		const [customer, setCustomer] = useState<CustomerType>(
			tab.customer || ({} as CustomerType)
		);
		const [avatar, setAvatar] = useState<string>(
			tab.customer?.avatar || ""
		);

		const handleSubmit = (e: any) => {
			e.preventDefault();

			onSubmit({
				title: title,
				thumbnail: thumbnail,
				link,
				description,
				customer: {
					...customer,
					avatar,
				},
			});
		};

		const handleImageSelector =
			(setImage: (image: string) => void) => () => {
				dispatch(
					setGlobalPopupElement(
						<ImagePopUpV2
							single={true}
							onUpdate={(images) => {
								const arrayImages = images.map((item: any) => {
									return {
										image: item.path,
										title: item.title,
										link: {} as LinkData,
									};
								});

								// setImages(arrayImages)
								const item = arrayImages[0];

								// setThumbnail(item.image);
								setImage(item.image);

								dispatch(setGlobalPopupElement(null));
							}}
							onClose={() => {
								dispatch(setGlobalPopupElement(null));
							}}
						/>
					)
				);
			};

		const handleLinkData = (data: LinkData, index: number) => {
			setLink(data);
		};

		return (
			<div
				key={index}
				className="py-4 w-full min-h-[100%] absolute left-0 top-0 z-50 bg-white !block !px-0">
				{/* close button */}
				<button
					type="button"
					className="absolute right-0 top-0 z-50 cursor-pointer p-1 rounded-full border border-red-400 bg-red-400"
					onClick={() => {
						setPopupElement(null);
					}}>
					<CloseIcon />
				</button>

				<div className="flex items-center justify-between gap-4 pb-2">
					<h3 className="underline pb-1">Tab {index + 1}</h3>
				</div>
				<div className="flex items-center gap-4">
					<div className="capitalize tracking-wide">Title:</div>
					<input
						value={title}
						onChange={(e) => {
							const title = e.target.value;
							setTitle(title);
						}}
						type="text"
						placeholder="type here...."
					/>
				</div>

				<div className="">
					<div className="capitalize tracking-wide">Description:</div>
					<textarea
						value={tab.description}
						onChange={(e) => {
							const description = e.target.value;
							setDescription(description);
						}}
						className="w-full h-24"
						placeholder="type here...."
					/>
				</div>

				<div className="flex items-center justify-between py-2 gap-4 w-[180px] h-[80px]">
					<div className="capitalize tracking-wide">Thumbnail:</div>
					<ImageContainerEditButton
						index={index}
						imageUrl={
							thumbnail
								? process.env.REACT_APP_IMAGE_URL + thumbnail
								: ""
						}
						handleImageSelector={handleImageSelector(setThumbnail)}
					/>
				</div>

				<LinkForm
					currnetLinkData={link || ({} as LinkData)}
					onUpdate={(data) => {
						handleLinkData(data, index);
					}}
				/>

				<div className="my-2 w-full [&>*]:mb-2">
					<p>Customer:</p>
					<div className="flex items-center gap-4">
						<div className="capitalize tracking-wide">Name:</div>
						<input
							value={customer?.name}
							onChange={(e) => {
								const name = e.target.value;
								setCustomer((prev) => ({
									...prev,
									name,
								}));
							}}
							type="text"
							placeholder="type here...."
						/>
					</div>

					<div className="flex items-center gap-4">
						<div className="capitalize tracking-wide">
							Subtitle:
						</div>
						<input
							value={customer?.subtitle}
							onChange={(e) => {
								const subtitle = e.target.value;
								setCustomer((prev) => ({
									...prev,
									subtitle,
								}));
							}}
							type="text"
							placeholder="type here...."
						/>
					</div>

					<div className=" flex items-center justify-between py-2 gap-4 w-[120px] h-[60px]">
						<div className="capitalize tracking-wide">Avatar:</div>
						<ImageContainerEditButton
							index={index}
							imageUrl={
								avatar
									? process.env.REACT_APP_IMAGE_URL + avatar
									: ""
							}
							handleImageSelector={handleImageSelector(setAvatar)}
						/>
					</div>
				</div>

				{/* submit btn */}
				<div className="mt-4">
					<button
						onClick={handleSubmit}
						className="w-full py-3 px-2 bg-yellow-400">
						Submit
					</button>
				</div>
			</div>
		);
	};

	return (
		<form
			onSubmit={handleSubmit}
			className="mainSliderForm__container relative">
			<h1 className="title capitalize">{choosenItem.name}</h1>

			<TopPanel panel={panel} onChange={(name) => setPanel(name)} />
			{/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}

			{popupElement === null && (
				<>
					{panel === "main" ? (
						<div className="py-4 [&>*]:mb-4">
							<div>
								{reviewsTabs.map((tab, index) => {
									return (
										<div
											key={index}
											className="p-2 py-4 rounded-md shadow-lg overflow-hidden w-full flex justify-between items-start gap-2">
											<div className="[&>*]:mb-2">
												<p>Title: {tab.title}</p>
												{/* <p className='truncate w-full whitespace-nowrap overflow-hidden'>Description: {tab.description}</p> */}
												{tab.thumbnail && (
													<div className="flex items-center gap-4">
														<div className="capitalize tracking-wide">
															Logo:
														</div>
														<img
															className="w-[60px] h-[40px] object-contain"
															src={
																process.env
																	.REACT_APP_IMAGE_URL +
																tab.thumbnail
															}
															alt=""
														/>
													</div>
												)}
												{tab.link?.url && (
													<p>
														Link:
														<span className="underline opacity-90 ml-2">
															/{tab.link?.url}
														</span>
													</p>
												)}
												{tab.customer?.name && (
													<p>
														Customer Name:{" "}
														{tab.customer?.name}
													</p>
												)}
												{tab.customer?.avatar && (
													<div className="flex items-center gap-4">
														<div className="capitalize tracking-wide">
															Customer avatar:
														</div>
														<img
															className="w-[60px] h-[40px] object-contain"
															src={
																process.env
																	.REACT_APP_IMAGE_URL +
																tab.customer
																	?.avatar
															}
															alt=""
														/>
													</div>
												)}
											</div>
											<div className="flex flex-col gap-2">
												<button
													className="text-gray-500 px-2 py-1 rounded-md border border-gray-400 bg-gray-100 hover:bg-white"
													onClick={(e) => {
														e.preventDefault();

														setPopupElement(
															<ProductTab
																index={index}
																tab={tab}
																onSubmit={(
																	tab
																) => {
																	const newTabs =
																		[
																			...reviewsTabs,
																		];
																	newTabs[
																		index
																	] = tab;

																	setReviewsTabs(
																		newTabs
																	);

																	setPopupElement(
																		null
																	);
																}}
															/>
														);
													}}>
													Edit
												</button>

												<button
													className="text-red-500 px-2 py-1 rounded-md border border-red-400 bg-red-100 hover:bg-white"
													onClick={(e) => {
														e.preventDefault();

														const newTabs = [
															...reviewsTabs,
														];
														newTabs.splice(
															index,
															1
														);

														setReviewsTabs(newTabs);
													}}>
													<DeleteIcon />
												</button>
											</div>
										</div>
									);
								})}

								{/* new add button */}
								<button
									className="text-gray-500 mt-4 px-3 py-2 rounded-md shadow-lg bg-gray-100 hover:bg-white w-full flex gap-4"
									onClick={(e) => {
										e.preventDefault();

										setPopupElement(
											<ProductTab
												index={reviewsTabs.length}
												onSubmit={(tab) => {
													setReviewsTabs([
														...reviewsTabs,
														tab,
													]);

													setPopupElement(null);
												}}
											/>
										);
									}}>
									<PlusIcon /> Add{" "}
									{reviewsTabs.length > 0
										? "New"
										: "Reviews Tab"}
								</button>
							</div>
						</div>
					) : (
						<div className="flex flex-col items-start gap-4 mt-3 pt-2 border-t">
							{/* <div className="flex items-center gap-4">
								<div>Col Limit : </div>
								<input
									className="w-20"
									type="number"
									value={colProductLimit}
									onChange={(e) =>
										setColProductLimit(
											Number(e.target.value)
										)
									}
								/>
							</div> */}

							<div className="flex items-center gap-4">
								{/* is vertical */}
								<input
									type="checkbox"
									defaultChecked={settings?.isVertical}
									onChange={() => {
										setSettings({
											...settings,
											isVertical: !settings?.isVertical,
										});
									}}
								/>
								<div className="whitespace-nowrap">
									Is Vertical
								</div>
							</div>

							{/* <ProductsDefaultSettings
								settings={settings}
								onChange={(key, value) => {
									setSettings({
										...settings,
										[key]: value,
									});
								}}
							/> */}
						</div>
					)}

					<div>
						<button className="mainSliderForm__btn">save</button>
					</div>
				</>
			)}

			{popupElement}
		</form>
	);
};

export default FormForReviewsSlider;
