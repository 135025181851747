export type Panel = "main" | "settings";

const defaultPanels: {
  name: Panel;
  title: string;
}[] = [
  {
    name: "main",
    title: "Main",
  },
  {
    name: "settings",
    title: "Settings",
  },
];

const TopPanel = ({
  onChange,
  panel,
  panels = defaultPanels,
}: {
  onChange: (name: Panel) => void;
  panel: Panel;
  panels?: { name: Panel; title: string }[];
}) => {
  return (
    <div className="flex flex-col md:flex-row border border-black/5 border-b-none mt-0">
      {panels.map((p) => (
        <button
          key={p.name}
          type="button"
          className={`flex-1 py-2 text-sm font-light tracking-wide text-center border-b-2 focus:outline-none ${
            panel === p.name
              ? "border-green-500 text-black"
              : "text-black/50 hover:text-black/70"
          }`}
          onClick={() => onChange(p.name)}
        >
          {p.title}
        </button>
      ))}
    </div>
  );
};

export default TopPanel;
